import React from 'react';
import bloguser from '../../assets/images/blog-user.png'
//import sb1 from '../../assets/images/s-b.png'
//import sb2 from '../../assets/images/coment.png';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const Content = (props) => {
    let disqusConfig = {
      url: `${props.restdata.location.href}`,
      identifier: props.restdata.pageContext.slug,
      title: props.data
      &&props.data.allWpPost
      &&props.data.allWpPost.edges
      &&props.data.allWpPost.edges[0]
      &&props.data.allWpPost.edges[0].node
      &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData
      &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData
      &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData.customTitle
    }
    return(
      <div className="container my-md-5 my-sm-1">
        <div className="row">
          <div className="col-md-2 col-lg-1 share-title text-center b-disp">
            SHARE
            <ul className="mx-0 mt-4 p-0 social-links">
              <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?${props.restdata.location.href}`}>
              <li>
                <svg width={11} height={20} viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.32991 3.32083H10.1557V0.140833C9.84074 0.0975 8.75741 0 7.49574 0C4.86324 0 3.05991 1.65583 3.05991 4.69917V7.5H0.154907V11.055H3.05991V20H6.62157V11.0558H9.40907L9.85157 7.50083H6.62074V5.05167C6.62157 4.02417 6.89824 3.32083 8.32991 3.32083Z" fill="#C4C4C4" />
                </svg>
              </li>
              </a>
              <a target="_blank" href={`http://twitter.com/share?text=Blog&url=${props.restdata.location.href}`}>
                  <li>
                    <svg width={20} height={18} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 2.79875C19.2563 3.125 18.4637 3.34125 17.6375 3.44625C18.4875 2.93875 19.1363 2.14125 19.4412 1.18C18.6488 1.6525 17.7738 1.98625 16.8412 2.1725C16.0887 1.37125 15.0162 0.875 13.8462 0.875C11.5763 0.875 9.74875 2.7175 9.74875 4.97625C9.74875 5.30125 9.77625 5.61375 9.84375 5.91125C6.435 5.745 3.41875 4.11125 1.3925 1.6225C1.03875 2.23625 0.83125 2.93875 0.83125 3.695C0.83125 5.115 1.5625 6.37375 2.6525 7.1025C1.99375 7.09 1.3475 6.89875 0.8 6.5975C0.8 6.61 0.8 6.62625 0.8 6.6425C0.8 8.635 2.22125 10.29 4.085 10.6712C3.75125 10.7625 3.3875 10.8062 3.01 10.8062C2.7475 10.8062 2.4825 10.7913 2.23375 10.7362C2.765 12.36 4.2725 13.5538 6.065 13.5925C4.67 14.6838 2.89875 15.3412 0.98125 15.3412C0.645 15.3412 0.3225 15.3263 0 15.285C1.81625 16.4563 3.96875 17.125 6.29 17.125C13.835 17.125 17.96 10.875 17.96 5.4575C17.96 5.27625 17.9538 5.10125 17.945 4.9275C18.7588 4.35 19.4425 3.62875 20 2.79875Z" fill="#C4C4C4" />
                    </svg>
                  </li>
              </a>
              <a href={`https://www.linkedin.com/cws/share?url=${props.restdata.location.href}`} target="_blank">
              <li>
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path d="M19.994 19.9999V19.999H19.999V12.664C19.999 9.07569 19.2265 6.31152 15.0316 6.31152C13.0149 6.31152 11.6616 7.41819 11.1091 8.46736H11.0507V6.64652H7.07324V19.999H11.2149V13.3874C11.2149 11.6465 11.5449 9.96319 13.7007 9.96319C15.8249 9.96319 15.8566 11.9499 15.8566 13.499V19.9999H19.994Z" fill="#C4C4C4" />
                        <path d="M0.330688 6.64746H4.47736V20H0.330688V6.64746Z" fill="#C4C4C4" />
                        <path d="M2.40167 0C1.07583 0 0 1.07583 0 2.40167C0 3.7275 1.07583 4.82583 2.40167 4.82583C3.7275 4.82583 4.80333 3.7275 4.80333 2.40167C4.8025 1.07583 3.72667 0 2.40167 0V0Z" fill="#C4C4C4" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                        <rect width={20} height={20} fill="white" />
                        </clipPath>
                    </defs>
                   </svg>
              </li>
              </a>
              
            </ul>
          </div>
          <div className="col-md-10 col-lg-11 blog-details">
            <div className="blog-user d-flex align-items-center justify-content-between">
            {props.data
            &&props.data.allWpPost
            &&props.data.allWpPost.edges
            &&props.data.allWpPost.edges[0]
            &&props.data.allWpPost.edges[0].node
            &&props.data.allWpPost.edges[0].node.author?
                (<div className="d-flex user-details"> 
                  <div className="w-50">
                      <img className="w-50 rounded" src={props.data.allWpPost.edges[0].node.author.node.avatar.url}/>
                  </div>
                  <div> 
                      <h5>{props.data.allWpPost.edges[0].node.author.node.name}</h5>
                  </div>
                </div>):(
                  <div className="d-flex user-details"> 
                    <span className="mr-3">
                        <img src={bloguser}/>
                    </span>
                    <div> 
                        <h5>Adam Smith</h5>
                        <p className="mb-0">Admin user</p>
                    </div>
                  </div>
                )
              }
              {props.data
               &&props.data.allWpPost
               &&props.data.allWpPost.edges
               &&props.data.allWpPost.edges[0]
               &&props.data.allWpPost.edges[0].node
               &&props.data.allWpPost.edges[0].node.date &&
               <div className=" user-details">
                  <h5>Posted on {props.data.allWpPost.edges[0].node.date}</h5>
                  <p className="mb-0">{props.data.allWpPost.edges[0].node.comments.nodes.length} Comments</p>
               </div>}
            </div>
            <hr />
            {props.data
               &&props.data.allWpPost
               &&props.data.allWpPost.edges
               &&props.data.allWpPost.edges[0]
               &&props.data.allWpPost.edges[0].node
               &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData
               &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData
               &&<h2 className="share-main-title w-100">{props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData.customTitle}</h2>
              }
            {props.data
              &&props.data.allWpPost
              &&props.data.allWpPost.edges
              &&props.data.allWpPost.edges[0]
              &&props.data.allWpPost.edges[0].node
              &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData
              &&props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData
              &&<div dangerouslySetInnerHTML={{__html:props.data.allWpPost.edges[0].node.TeamlanceSingleBlogData.customData.customContent}}></div>

            }
            
            <CommentCount config={disqusConfig} placeholder={'...'} />
            <Disqus config={disqusConfig} />
          </div>
        </div>
      </div>
    )
}

export default Content;

