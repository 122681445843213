import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

const RelatedBlog = (props) => {

    return(
     <StaticQuery
      query={graphql`
         query {
            allWpPost {
                edges {
                  node {
                    content
                    TeamlanceSingleBlogData {
                      customData {
                        customContent
                        customTitle
                      }
                    }
                    slug
                    status
                    title
                    date(formatString: "YYYY-MM-DD")
                    featuredImage {
                      node {
                        sourceUrl
                      }
                    }
                    categories {
                      nodes {
                        name
                        slug
                      }
                    }
                  }
                }
              }
         }
      `}
      render={data => {

          let allcategory = props.data&&props.data.allWpPost
          &&props.data.allWpPost.edges&&props.data.allWpPost.edges[0]
          &&props.data.allWpPost.edges[0].node
          &&props.data.allWpPost.edges[0].node.categories;

          let catdata = [];

          allcategory && allcategory.nodes.forEach((data,key)=>{
              catdata.push(data.slug);
          })

         if(data && data.allWpPost && data.allWpPost.edges){
            
           var allresult = data.allWpPost.edges.filter((element) => {
                return element.node.categories.nodes.filter((cat)=>{
                    return catdata.includes(cat.slug) && element.slug !== props.data.allWpPost.edges[0].node.slug
                });
            })
         }

          return(
            <div className="container most-read">
            <div className="row d-flex justify-content-between mb-3 align-items-center">
                <h1 className="related-title mt-5">Related Articles</h1>
            </div>
            <div className="row">
                 {allresult
                  &&allresult.length>0&&
                  allresult.slice(0, 3).map((blog,key)=>{
                        return(
                           <div key={key} className="gallery_product col-lg-4 col-md-4 col-sm-6 col-xs-6 filter hdpe">
                              <div>   
                                 <img src={blog.node && blog.node.featuredImage.node.sourceUrl} className="img-responsive"/>
                              </div>
                              <div className="post-details">
                              {blog.node 
                               &&blog.node.categories
                               &&blog.node.categories.nodes&&
                              <p className="tag">
                                 {blog.node.categories.nodes.map((cat, key)=>{
                                       return(<span key={key} className="ml-2">{cat.name}</span>)
                                 })
                                    
                                 }
                                 
                              </p>}
                                 <h5>{blog.node && blog.node.title}</h5>
                                 <div dangerouslySetInnerHTML={{__html:blog.node && blog.node.content}}></div>
                                 <div className="read-btn">
                                    <Link to={`/blog/${blog.node && blog.node.slug}`}>Read More</Link>
                                 </div>
                              </div>
                            </div> 
                        )
                  })}
            </div>
         </div>
          )
        }}
      />   
    )
}

export default RelatedBlog;