import React from 'react';
import Layout from "../components/layout";
import Banner from "../components/singleblog/banner";
import Content from "../components/singleblog/content";
import RelatedBlog from '../components/singleblog/relatedblog';
import SEO from "../components/seo";
import {graphql} from "gatsby";
const Post = (props) => {
    return(
        <Layout {...props}>
            <SEO title="Blog" />
            <Banner data={props.data}/>
            <Content restdata={props} data={props.data}/>
            <RelatedBlog data={props.data}/>
         </Layout>
    ) 
}

export const post = graphql`
  query($slug: String!) {
    allWpPost(
      filter: {slug: {eq: $slug}}
    ) {
      edges {
        node {
          content
          TeamlanceSingleBlogData {
            customData {
              customContent
              customTitle
            }
          }
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          date(formatString: "MMMM Do, YYYY")
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
          comments {
            nodes {
              content
            }
          }
        }
      }
    }
   }
`;

export default Post;