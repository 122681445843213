import React from 'react';
import blogsingle from '../../assets/images/blog-single.png'

const Banner = (props) => {
    return(
        <>
           <div className="col-12 wrapper blog-banner-outer main-bggrey p-0 single-blog-inner">
               <div className="col-12 inner-page-content home-inner px-0">
                  <section className=" ">
                      <div className="single-blog-banner">
                          {props.data
                           &&props.data.allWpPost
                           &&props.data.allWpPost.edges
                           &&props.data.allWpPost.edges[0]
                           &&props.data.allWpPost.edges[0].node?(
                               <img src={props.data.allWpPost.edges[0].node.featuredImage.node.sourceUrl} alt="case study" />
                           ):(
                               <img src={blogsingle} alt="case study" />
                           )}
                      </div>
                  </section>
               </div>
           </div>
        </>
    )
}

export default Banner;